@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Gothic+A1&display=swap');

//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    background: #F2F1F1;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";

.under-construction {
    font-family: "Gothic A1", sans-serif;
    font-size: 2rem;
    // font-weight: 300;
    text-transform: uppercase;
    color: #6b6b6b;
}